import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import image1 from '../assets/images/image1.jpg';
import image2 from '../assets/images/image2.jpg';
import config from '../../config';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Our Vision
          </h2>
          <p>
            Our vision is to strive to constantly increase efficiency and effectiveness for our clients and by
            <br />
            providing comprehensive tailor-made solutions for client's needs
          </p>
        </header>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={image1} alt="" />
        </div>
        <div className="content">
          <h2>
            Business Values
          </h2>
          <div>
            <div>&bull; Integrity when dealing with clients.</div>
            <div>&bull; Promoting hygiene.</div>
            <div>&bull; Excellent clients service.</div>
            <div>&bull; Caring for our people.</div>
            <div>&bull; Teamwork both within our business with our clients.</div>
          </div>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={image2} alt="" />
        </div>
        <div className="content">
          <h2>
            Our process:
            <br />
            Cleaning Services
          </h2>
          <p>
            We would like the opportunity to submit a proposal for
            custodial services for your building or office. Our
            purpose in compiling this information is to offer you
            the finest available service at the possible cost. We will
            perform a survey of your building to ensure that we can
            provide you with the services that you desire.
          </p>
        </div>
      </section>
      {/* <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            Augue eleifend aliquet
            <br />
            sed condimentum
          </h2>
          <p>
            Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
            imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
          </p>
        </div>
      </section> */}
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>What we do</h2>
          <p>
            We are proficient in the application and implementation of the following services:
          </p>
        </header>
        <ul className="features">
          <li className="icon fa-building">
            <h3>Office cleaning and Janitorial Services</h3>
            <p>
              Complete office cleaning from the smallest office environment to large executive
              suites, all individually tailored to suit your specific requirements. We clean all
              types of floors including carpet and vacuum cleaning. We maintain all types of
              floors professionally. We also do all janitorial services from sanitary bins to toilet
              rolls, soap dispensers etc.
            </p>
          </li>
          <li className="icon solid fa-hand-sparkles">
            <h3>Pre-occupational and construction cleaning</h3>
            <p>
              We have staff that are trained for the challenges of new construction and remodel
              cleaning. Window cleaning presents a lot of challenges in removing construction
              dust, debris, paint etc. form windows and frames without scratching. We can
              provide this service to single family dwellings to large commercial buildings, and
              have the expertise to do it professionally and with the safety of our customers
              premises in mind.
            </p>
          </li>
          <li className="icon solid fa-spray-can-sparkles">
            <h3>House Cleaning</h3>
            <p>
              We dust any ceiling fans, lights, min blinds and other high dusting. We clean wall
              decorations, picture frames, light switches, window sills, doors and door trim. We dust, clean
              and/or polish all furniture and all decorative items. We clean base boards quarterly and we
              sweep, mop and vacuum.
            </p>
          </li>
          <li className="icon solid fa-rug">
            <h3>Carpets & Window Cleaning</h3>
            <p>
              Our carpet cleaning always includes pre-vacuuming, pre-spotting and pile raking. We do
              windows and mini blinds. Let the sun shine in a whole different way! and respect your
              landscaping so as not to damage it.
            </p>
          </li>
          <li className="icon solid fa-person-digging">
            <h3>Construction</h3>
            <div>
              <div>&bull; Painting</div>
              <div>&bull; Tiling General</div>
              <div>&bull; Building</div>
              <div>&bull; Plumbing</div>
            </div>
          </li>
          <li className="icon solid fa-car-side">
            <h3>Motor Retail</h3>
            <div>
              <div>&bull; Tyre management/tyre repair kit</div>
              <div>&bull; Window protection film</div>
              <div>&bull; Paintwork protection film</div>
              <div>&bull; Headlight & bumper protection</div>
              <div>&bull; Leather interior protection</div>
              <div>&bull; Full protection film package</div>
              <div>&bull; Car collection service</div>
              <div>&bull; Car delivery service</div>
              <div>&bull; License and registration plate installations</div>
              <div>&bull; Car laundry</div>
            </div>
          </li>
          {/* <li className="icon fa-flag">
            <h3>Tortor Ut</h3>
            <p>
              Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem
              tincidunt nullam amet leo Aenean ligula consequat consequat.
            </p>
          </li> */}
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Why Us?</h2>
          <p>
            We prioritise reliability, attention to detail and
            remarkable professionalism. We believe in team work
            both within our business and during our dealings with
            our clients. Our staff is well trained and has excellent
            client services.
          </p>
        </header>
        <section>
          <form method="POST" data-netlify="true" data-netlify-honeypot="bot-field" name="contact">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="row gtr-uniform">
              <div className="col-6 col-12-xsmall">
                <input type="text" name="firstName" id="firstName" defaultValue="" placeholder="Firstname" />
              </div>
              <div className="col-6 col-12-xsmall">
                <input type="text" name="lastName" id="lastName" defaultValue="" placeholder="Lastname" />
              </div>
              <div className="col-12">
                <input type="email" name="email" id="email" defaultValue="" placeholder="Email" />
              </div>
              <div className="col-12">
                <textarea name="message" id="message" placeholder="Enter your message" rows="6"></textarea>
              </div>
              <div className="col-12">
                <ul className="actions">
                  <li><input type="submit" value="Send Message" className="primary" /></li>
                  <li><input type="reset" value="Reset" /></li>
                </ul>
              </div>
            </div>
          </form>
        </section>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
